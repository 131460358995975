import React, { Component } from "react";
import * as ImIcons from "react-icons/im";
import "./testimonial.scss";

class testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="testimonial-container">
        <div className="title">
          <h2>Testimonial</h2>
          <div className="underline"></div>
        </div>
          <div className="card-container">
            <div className="card">
              <div className="comment">
                <p>
                  <ImIcons.ImQuotesLeft/>
                  &nbsp;Appearance, exhaust, and modified engine performance were all carefully researched, presented to me, and then carried out exactly as we agreed. 
                  The amazing aspect from my perspective is the speed at which you had the work done in my experience, work of this type takes months, even years with some custom shops. 
                  You had the entire project done in less than two weeks! I cannot tell you how impressive this is. For someone like me, who wants and expects detailed and exacting custom work
                  to have it done in less than two weeks really is astounding. This was an entirely pain-free experience, it was practically as easy as "point, and it's done". You took the car,
                  supervised all aspects of the conversion, and came back to me with a 100% job.
                  <ImIcons.ImQuotesRight className="RQuote" />
                </p>
              </div>
              <div className="name">
                <p>
                  <span>C.W. Bruce Menchions, </span><b> B.Comm, MBA, MSc, MD </b>
                </p>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default testimonial;
