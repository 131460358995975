import React, { Component } from "react";
import "./footer.scss";

class footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="footer-container">
        <p>Copyright© 2021 by bdcustompa, All Rights Reserved. </p>
      </div>
    );
  }
}

export default footer;
