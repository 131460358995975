// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./utils/fonts/SF-Pro-Display-Black.otf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box; }\n\nbody {\n  font-family: -apple-system, system-ui, BlinkMacSystemFont;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");\n  color: #f9f9f9;\n  background-color: #111; }\n", "",{"version":3,"sources":["webpack://src/index.scss","webpack://src/utils/variables.scss"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB,EAAA;;AAGxB;ECME,yDAAyD;EACzD,+DAA+D;EDL/D,cCNa;EDOb,sBAAsB,EAAA","sourcesContent":["@import \"utils/variables.scss\";\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  @include primaryFontFamily;\n  color: $white;\n  background-color: #111;\n}\n","// Google fonts\n@import url(\"https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap\");\n\n// color section\n$white: #f9f9f9;\n$primary-color: #1d1d1d;\n$secondary-color: #212121;\n$light: #333333;\n$secondary-light: #474747;\n\n// functions\n\n@mixin primaryFontFamily {\n  // font-family: \"Montserrat\", sans-serif;\n  font-family: -apple-system, system-ui, BlinkMacSystemFont;\n  src: url(\"./fonts/SF-Pro-Display-Black.otf\") format(\"opentype\");\n}\n\n@mixin secondFontFamily {\n  //font-family: \"Open Sans\", sans-serif, Arial, Verdana, \"Trebuchet MS\";\n  font-family: -apple-system, system-ui, BlinkMacSystemFont;\n  src: url(\"./fonts/SF-Pro-Display-Black.otf\") format(\"opentype\");\n}\n\n@mixin centerFlex {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
