import React, { Component } from "react";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import "./location.scss";

class location extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {    
    return (
      <div id="location-container">

        <div className="info-box">
          <h2 id='contactStyle'>Get In Touch With Us</h2>

          <div className="info-item">
            <AiIcons.AiOutlineMobile />
            <a href="tel:+14168852050">(416) 885-2050</a>
          </div>

          <div className="info-item">
            <AiIcons.AiOutlineMail />
            <a href="mailto:info@bdcustompa.com">info@bdcustompa.com</a>
          </div>

          <h3>Follow us on</h3>

          <div className="social-box">
            <div className="button">
              <a
                href="https://www.instagram.com/bd_custom_pa/"
                className="social-icon"
                target="blank"
              >
                <AiIcons.AiFillInstagram />
                <span>Instagram</span>
              </a>
            </div>

            <div className="button">
              <a
                href="https://www.facebook.com/pages/category/Product-Service/BD-Custom-Performance-101954038448058/"
                className="social-icon"
                target="blank"
              >
                <FaIcons.FaFacebook />
                <span>FaceBook</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default location;
